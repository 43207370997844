<template>
  <div class="main-container">
    <span class="rem30 borrow-money">融资金额</span>
    <van-field
      label-width="0"
      v-model="value"
      :placeholder="maxAmtText"
      type="number"
      @blur="clearLoanPeriod"
    />
    <van-cell is-link title="融资用途" @click="loanUseShow = true" :value="loanUse" />
    <van-action-sheet v-model="loanUseShow" :actions="loanUseList" @select="loanUseSelect" />
    <van-cell is-link title="融资期限" @click="loanPeriodFn" :value="loanPeriod" />
    <van-action-sheet
      v-model="loanPeriodShow"
      :actions="loanPeriodList"
      @select="loanPeriodSelect"
    />
    <van-cell is-link title="工作性质" @click="workNatureShow = true" :value="workNature" />
    <van-action-sheet
      v-model="workNatureShow"
      :actions="workNatureList"
      @select="workNatureSelect"
    />
    <van-cell is-link title="付款计划" value="预览" @click="trialLoanRepayPlan" />
    <div class="mt40">
      <van-button round block type="info" native-type="submit" @click="nextStep">下一步</van-button>
    </div>
    <!-- <div class="text-center c-666666 rem26 mt14" v-if="allPrepayDay > 0">
      <span>{{ allPrepayDay }}天后可以提前付款，按日计息</span>
    </div> -->
    <!-- <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }">
      123
    </van-popup> -->
    <repayment-plan ref="repayment" :repaymentLoan="repaymentLoan"></repayment-plan>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, ActionSheet, Cell, Popup, Button } from 'vant'
import {
  getProductInitInfoApi,
  preMatchApi,
  loanApplyApi,
  trialLoanRepayPlanApi,
} from '@/apis/borrow/index'
import repaymentPlan from '@/components/repayment-plan.vue'
import { mapState } from 'vuex'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(ActionSheet)
Vue.use(Cell)
Vue.use(Popup)
Vue.use(Button)

export default {
  components: {
    repaymentPlan,
  },
  data() {
    return {
      value: '',
      // 融资用途
      loanUse: '请选择',
      loanUseCode: '',
      loanUseShow: false,
      loanUseList: [],
      // 融资期限
      loanPeriod: '请选择',
      loanPeriodShow: false,
      loanPeriodList: [],
      // 工作性质
      workNature: '请选择',
      workNatureCode: '',
      workNatureShow: false,
      workNatureList: [],
      maxAmtText: '',
      periods: '', // 融资期数
      applyTnrTyp: '', // 融资期限单位D天 M月 Y年
      rmTimeUnitValue: '', // 融资期限间隔值
      mtdCde: '', // 付款方式
      mtdTyp: '', //  付款方式代码
      tdCodeVOList: [], // 付款方式list
      isPreMatch: false,
      repaymentLoan: {},
      allPrepayDay: '', // 几天可以提前付款
      productInit: {},
    }
  },
  created() {
    // this.myLoan = this.$store.state.repayment.loan
    this.getProductInitInfo()
  },
  computed: {
    ...mapState({
      productCode: (state) => state.common.productCode,
      inChannel: (state) => state.user.channel.inChannel,
    }),
  },
  methods: {
    // 查询融资用途、工作性质、融资期限
    getProductInitInfo() {
      getProductInitInfoApi({ productCode: this.productCode }).then((res) => {
        if (res.code === 200) {
          this.productInit = res.data
          this.allPrepayDay = this.productInit.allPrepayDay || 0
          this.maxAmtText = `￥最高可融资${this.productInit.maxAmt}`
          this.tdCodeVOList = this.productInit.productConfigMtdCodeVOList // 付款方式
          this.workNatureList = this.productInit.scomCodeList1 // 工作性质
          this.loanUseList = this.productInit.scomCodeList2 // 融资用途
          this.workNatureList.forEach((item) => {
            item.name = item.comCodeDesc
          })
          this.loanUseList.forEach((item) => {
            item.name = item.comCodeDesc
          })
        }
      })
    },
    loanPeriodFn() {
      if (!this.value) {
        this.$common.toast('请输入融资金额')
        return false
      }
      this.preMatch()
    },
    // 预选放款路由
    preMatch() {
      if (!this.$validate.istwoPoint(Number(this.value))) {
        this.$common.toast('请输入正数,且最多两位小数！')
        return
      }
      preMatchApi({
        productCode: this.productCode,
        applyAmt: this.value,
      }).then((res) => {
        if (res.code === 200) {
          this.loanPeriodShow = true
          this.loanPeriodList = res.data.tnrOptList
          this.loanPeriodList.forEach((item) => {
            item.name = item.termName
          })
        } else if (res.code == 3000) {
          this.$common.toast(res.data.msg)
        }
      })
    },
    clearLoanPeriod() {
      this.periods = ''
      this.loanPeriod = '请选择'
      this.loanPeriodList = []
    },
    // 付款试算
    trialLoanRepayPlan() {
      if (!this.value) {
        this.$common.toast('请输入融资金额')
        return
      }
      if (!this.loanUse || this.loanUse == '请选择') {
        this.$common.toast('请选择融资用途')
        return
      }
      if (!this.loanPeriod || this.loanPeriod == '请选择') {
        this.$common.toast('请选择融资期限')
        return
      }
      if (!this.workNature || this.workNature == '请选择') {
        this.$common.toast('请选择工作性质')
        return
      }
      let params = {
        trailScene: 1, // 试算业务场景1-融资申请 2-提款
        applyAmt: this.value, // 贷款金额
        typCde: this.productCode, // 贷款品种编码
        applyTnr: this.periods, // 融资期数
        applyTnrTyp: this.applyTnrTyp, // 融资期限单位D天 M月 Y年
        mtdCde: this.mtdCde, // 付款方式
        mtdTyp: this.mtdTyp, //  付款方式代码
        tnrTypVal: this.rmTimeUnitValue,
      }
      // this.showloading()
      trialLoanRepayPlanApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.repaymentLoan = res.data
            this.$refs.repayment.borrowShow = true
          }
          this.hideloading()
        })
        .catch(() => {
          this.hideloading()
        })
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    // 下一步
    nextStep() {
      if (!this.value) {
        this.$common.toast('请输入融资金额')
        return
      }
      if (!this.loanUse || this.loanUse == '请选择') {
        this.$common.toast('请选择融资用途')
        return
      }
      if (!this.loanPeriod || this.loanPeriod == '请选择') {
        this.$common.toast('请选择融资期限')
        return
      }
      if (!this.workNature || this.workNature == '请选择') {
        this.$common.toast('请选择工作性质')
        return
      }
      if (!this.$validate.istwoPoint(Number(this.value))) {
        this.$common.toast('请输入正数,且最多两位小数！')
        return
      }
      if (Number(this.value) > Number(this.productInit.maxAmt)) {
        this.$common.toast(`最高可融资${this.productInit.maxAmt}元`)
        return
      }
      if (Number(this.value) < Number(this.productInit.minAmt)) {
        this.$common.toast(`最少融资${this.productInit.minAmt}元`)
        return
      }

      this.showloading()
      let params = {
        applyAmt: this.value, // 融资金额
        productCode: this.productCode, // 产品类型
        purpose: this.loanUseCode, // 融资用途
        jobType: this.workNatureCode, // 工作性质
        applyTnr: this.periods, // 融资期数
        applyTnrTyp: this.applyTnrTyp, // 融资期限单位D天 M月 Y年
        tnrTypVal: this.rmTimeUnitValue, // 融资期限间隔值
        inChannel: this.inChannel,
      }
      console.log(params)

      loanApplyApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.hideloading()
            this.$router.push({
              path: '/apply-info',
            })
            this.$store.commit('SET_LOANNO', res.data.loanNo)
            this.$store.commit('SET_APPLY_SEQ', res.data.applySeq)
          } else {
            this.hideloading()
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
    // 选择融资用途
    loanUseSelect(item) {
      this.loanUse = item.name
      this.loanUseCode = item.comCode
      this.loanUseShow = false
    },
    // 选择融资期限
    loanPeriodSelect(item) {
      this.rmTimeUnitValue = item.rmTimeUnitValue
      this.applyTnrTyp = item.rmTimeUnit
      this.periods = item.periods
      this.loanPeriod = item.name
      this.tdCodeVOList.forEach((val) => {
        if (item.tnrOpt === val.tnrOpt) {
          this.mtdCde = val.mtdCode
          this.mtdTyp = val.mtdType
        }
      })
      this.loanPeriodShow = false
    },
    // 选择工作性质
    workNatureSelect(item) {
      this.workNature = item.name
      this.workNatureCode = item.comCode
      this.workNatureShow = false
    },
    // nextStep() {

    // },
  },
}
</script>
<style lang="less" scoped>
.borrow-money {
  color: #585c68;
}

.van-cell {
  padding: 0;
  padding: 0.26rem 0;
  border-bottom: 1px solid #e7e7e7;
}
</style>
